.spin {
  animation: Load-spin infinite 2s linear;
}

@keyframes Load-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
